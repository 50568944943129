<template>
  <div>
    <nav class="main-nav">
      <div class="main-nav_wrapper">
        <a href="#" class="menuBtn" @click="changeMenu" v-bind:class="{ act: isActive }">
          <span class="lines"><span>Menu Mobile</span></span>
        </a>
        <div class="wrapper-header-items" v-bind:class="{ 'menu-mobile act': isActive }">
          <div style="position: relative;">
            <a href="#" title="Logo Crypto Rebel">
              <img
                :src="require(`@/assets/images/icon-170x170.png`)"
                alt="Rebel" />
                <span style="display: none;">CryptoRebel Logo</span>
            </a>
          </div>
          <div>
            <ul>
              <li>
                <a href="#" target="_blank" rel="noopener"
                  @click.prevent="openWiki()">Whitepaper</a>
              </li>
            </ul>
          </div>
          <SocialMedia v-bind:class="{ act: isActive }" />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import SocialMedia from '@/components/SocialMedia.vue';

export default {
  name: 'MainMenu',
  props: {
    fullpage: Object,
  },
  components: {
    SocialMedia,
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    openGleam() {
      this.$store.dispatch('changeGleam', true);
    },
    openWiki() {
      this.$store.dispatch('changeWiki', true);
    },
    changeMenu() {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style scoped lang="scss">
.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  z-index: 15;

  &_wrapper {
    max-width: 1560px;
    margin: 0 auto;
  }

  .wrapper-header-items {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    > div {
      width: 33.33%;
      display: inline-block;
    }
  }

  @include below(1023px) {
    ul {
      li:not(:first-child) {
        display: none;
      }
    }
  }

  img {
    width: auto;
    height: 61px;
  }
}

ul {
  list-style-type: none;
  padding: 0;
  display: inline-flex;
  justify-content: flex-end;
}

li {
  display: inline-block;
  margin: 0 10px;
  color: #fff;
}

a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: bold;
}

/* menu button */
.menuBtn {
  height: 30px;
  width: 30px;

  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 101;
  display: none;

  @include below(1023px) {
    display: block;
  }

  > span {
    background-color: #fff;
    border-radius: 1px;
    height: 2px;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -1px 0 0 -15px;
    transition: height 100ms;
    &:after, &:before {
      content: '';
      background-color: #fff;
      border-radius: 1px;
      height: 2px;
      width: 100%;
      position: absolute;
      left: 50%;
      margin-left: -15px;
      transition: all 200ms;
    }
    &:after {
      top: -7px;
    }
    &:before {
      bottom: -7px;
    }
    span {
      display: none;
    }
  }
  &.act {
    > span {
      height: 0;
      &:after, &:before {
        background-color: #008877;
        top: 1px;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(-45deg);
      }
    }
  }
}
/* main menu block */
.menu-mobile {
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 100%;
  display: table;
  text-align: center;
  opacity: 0;
  transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform: scale(0);
  display: none;

  @include below(1023px) {
    display: table;
  }

  &.act {
    opacity: 1;
    transform: scale(1);
    ul li {
      opacity: 1;
      transform: translateX(0);
      display: block;
    }
  }
  ul {
    display: table-cell;
    vertical-align: middle;

    @include mobile() {
      height: 100vh;
    }
  }
  li {
    padding: 8px 0;
    transition: all 400ms 510ms;
    opacity: 0;
    float: none;
    display: block;

    @include mobile() {
      padding: 0;
    }

    img {
      width: 100px;
      height: 100px;
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-child(odd) {
      transform: translateX(30%);
    }
    &:nth-child(even) {
      transform: translateX(-30%);
    }
    &:last-child {
      transform: none;
    }
  }
  a {
    color: #19b698;
    display: inline-block;
    font-size: 18px;
  }
}
</style>
