<template>
  <div>
    <ul class="lst-social-media">
      <li>
        <a href="https://discord.gg/FTgMSYUy" target="_blank" rel="noopener" title="Discord">
          <img :src="require(`@/assets/images/icon-discord.svg`)"
            alt="Discord Icon" />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/CryptoRebelGame" target="_blank" rel="noopener" title="Twitter">
          <img :src="require(`@/assets/images/icon-twitter.svg`)"
            alt="Twitter Icon" />
        </a>
      </li>
      <li>
        <a href="https://t.me/+7rC2N_SFXEg1Y2Mx" target="_blank" rel="noopener" title="Telegram">
          <img :src="require(`@/assets/images/icon-telegram-app.svg`)"
            alt="Telegram Icon" />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/cryptorebelgame/" class="icon-instagram" target="_blank" rel="noopener" title="Instagram">
          <img :src="require(`@/assets/images/icon-instagram.svg`)"
          alt="Instagram Icon" />
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCbPJgVEa2GK9ICwnXl7yegg" target="_blank" rel="noopener" title="Youtube">
          <img :src="require(`@/assets/images/icon-youtube.svg`)"
          alt="Youtube Icon" />
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/CryptoRebelGame" target="_blank" rel="noopener" title="Facebook">
          <img :src="require(`@/assets/images/icon-facebook.svg`)"
          alt="Facebook Icon" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
// import Sound from '@/components/Sound.vue';

export default {
  name: 'SocialMedia',
  components: {
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped lang="scss">
.lst-social-media {
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin-bottom: 30px;
  z-index: 6;

  li {
    display: inline-block;
    background: #fff;
    border-radius: 50%;
    width: 70px;
    margin: 0 20px;
    border: 2px solid #fff;

    @include below(768px) {
      width: 35px;
      margin: 0 8px;
    }

    &:hover {
      // border: 2px solid #000;
      img {
        filter: invert(0.5);
      }
    }

    &:last-child {
      img {
        position: relative;
        left: -2px;
      }
    }

    a {
      display: block;
      padding: 10px;
    }

    img {
      display: block;
      height: 100%;
      width: 100%;
      position: relative;
    }
  }
}

.main-nav {
  .lst-social-media {
    position: static;
    transform: translate(0, 0);
    justify-content: flex-end;
    display: flex;
    margin: 0;

    @include below(1023px) {
      display: none;
    }

    li {
      background: transparent;
      width: 50px;
      margin: 0 0 0 15px !important;
      border-width: 1px;
      border-color: transparent;

      img {
        filter: invert(1);
      }

      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
  }

  .act {
    .lst-social-media {
      display: block;
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translate(-50%, -50%);
      justify-content: center;

      li {
        margin: 0 0 0 5px !important
      }
    }
  }
}
</style>
