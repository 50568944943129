import { createRouter, createWebHistory } from 'vue-router';
import CommingSoon from '../views/CommingSoon.vue';
// import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'commingSoon',
    component: CommingSoon,
  },
  {
    path: '/wiki',
    name: 'commingSoonWiki',
    component: CommingSoon,
  },
  // {
  //   path: '/nfts',
  //   name: 'nfts',
  //   component: () => import(/* webpackChunkName: "NFTs" */ '../views/NFTs.vue'),
  // },
  // {
  //   path: '/privatesale',
  //   name: 'privatesale',
  //   component: () => import(/* webpackChunkName: "PrivateSale" */ '../views/PrivateSale.vue'),
  // },
  // {
  //   path: '/registration',
  //   name: 'Home2',
  //   component: HomeView,
  // },
  // {
  //   path: '/slot-machine',
  //   name: 'slot-machine',
  //   // route level code-splitting
  //   // this generates a separate chunk (SlotMachine.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "slotmachine" */ '../views/SlotMachine.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
