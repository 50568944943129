<template>
  <div class="video-container-modal">
    <iframe id="player-1" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="Crypto Rebel Teaser" src="https://www.youtube.com/embed/DGyo-a7gPUQ?autoplay=1&amp;controls=0&amp;enablejsapi=1&amp;iv_load_policy=3&amp;disablekb=1&amp;showinfo=0&amp;rel=0&amp;start=0&amp;widgetid=1"></iframe>
  </div>
</template>

<script>
export default {
  name: 'TrailerVideo',
  components: {
  },
  data() {
    return {
    };
  },
};
</script>
