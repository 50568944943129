import { createStore } from 'vuex';

export default createStore({
  state: {
    contact: false,
    trailer: false,
    comicAbout: false,
    comic: false,
    wiki: false,
    share: false,
    audio: false,
    images: false,
    video1: null,
    fullpage: null,
    loader: true,
    gleam: false,
    isMobile: false,
  },
  getters: {
  },
  mutations: {
    contact(state) {
      state.contact = !state.contact;
    },
    trailer(state, value) {
      state.trailer = value;
    },
    mobile(state, value) {
      state.mobile = value;
    },
    comicAbout(state, value) {
      state.comicAbout = value;
    },
    comic(state, value) {
      state.comic = value;
    },
    gleam(state, value) {
      state.gleam = value;
    },
    wiki(state, value) {
      state.wiki = value;
    },
    share(state, value) {
      state.share = value;
    },
    images(state) {
      state.images = !state.images;
    },
    video1(state, player) {
      state.video1 = player;
      state.video1Playing = true;
    },
    sound(state) {
      state.audio = !state.audio;
    },
    loader(state, value) {
      state.loader = value;
    },
  },
  actions: {
    changeContact(context) {
      context.commit('contact');
    },
    isMobile(context, value) {
      context.commit('mobile', value);
    },
    changeGleam(context, value) {
      context.commit('gleam', value);
    },
    changeTrailer(context, value) {
      context.commit('trailer', value);
    },
    changeComicAbout(context, value) {
      context.commit('comicAbout', value);
    },
    changeComic(context, value) {
      context.commit('comic', value);
    },
    changeWiki(context, value) {
      context.commit('wiki', value);
    },
    changeShare(context, value) {
      context.commit('share', value);
    },
    changeSound(context) {
      context.commit('sound');
    },
    changeImages(context) {
      context.commit('images');
    },
    changeLoader(context, value) {
      context.commit('loader', value);
    },
    SetPlayer1(context, player) {
      context.commit('video1', player);
    },
  },
  modules: {
  },
});
