<template>
  <header class="header">
    <div class="text-content">
      <img class="rebel-main-logo"
        :src="require(`@/assets/images/rebel-clean-2.png`)"
          alt="Rebel" />
      <h1 class="neon-red">
        PLAY TO EARN COMIC
      </h1>
      <p>
        Crypto Rebel is a play-to-earn and own game, and comic.
        Race to the bitter end through a dystopian,
        cyber city called Rebel City (known before as NYC).
        Everything is a race and a fight for scarce resources
        in a dangerous world that's crumbling all around you.
        All you have to do is get yourself a drive,
        (choose your character), and strap in for the rush of your life.
        Earn Rebel $Dollars in races and withdraw
        them into your crypto wallet.
        Written as a comic book series
        and best-selling graphic novel in 1984 by Pepe Moreno.
      </p>
      <a href="#" class="main-icon" @click.prevent="openContact()">
        <video autoplay muted loop width="140" height="140" class="icon-coin"
          v-if="!$store.state.mobile && !isSafari()">
          <source :src="require(`@/assets/videos/rebel_coin.webm`)"
            type="video/webm">
        </video>
        <img
          :src="require(`@/assets/images/coin-mobile.png`)"
          v-if="$store.state.mobile || isSafari()"
          alt="Crypto Rebel Coin" />
        <span class="neon-red">Whitelist<br />Coming Soon</span>
      </a>
      <div class="wrapper-buttons">
        <a href="https://discord.gg/FTgMSYUy" class="button btn-community button-1" target="_blank"
          v-bind:class="{ 'safari': isSafari() }">
          <img :src="require(`@/assets/images/icon-discord.svg`)" alt="Discord Icon">
          Join the community now
        </a>
        <a href="javascript:;" class="button button-2 btn-read-the-comic"
          v-bind:class="{ 'safari': isSafari() }"
          @click="$store.dispatch('changeComic', true)">
          Read the Comic
        </a>
        <a href="javascript:;" class="button button-2 btn-read-the-comic btn-teaser"
          v-bind:class="{ 'safari': isSafari() }"
          @click="$store.dispatch('changeTrailer', true)">
          Play the Teaser
        </a>
        <Sound v-if="!$store.state.mobile" />
      </div>
    </div>
    <img class="rebel-figure"
      :src="require(`@/assets/images/rebel-figure-3d-mobile.png`)"
      v-if="$store.state.mobile"
      alt="Rebel Character" />
    <div class="wrapper-rebel-figure" v-if="!$store.state.mobile">
      <img class="rebel-figure light-1"
        :src="require(`@/assets/images/rebel-figure-3d-v2.webp`)"
        alt="Rebel Character"
        v-if="isSafari()" />
      <video class="bg-rebel" autoplay muted loop v-if="!isSafari()">
        <source :src="require(`@/assets/videos/rebel-lightning-1805.webm`)"
          type="video/webm">
      </video>
    </div>
  </header>
</template>

<script>
import Sound from '@/components/Sound.vue';

export default {
  name: 'Header-comp',
  components: {
    Sound,
  },
  data() {
    return {
    };
  },
  methods: {
    openContact() {
      this.$store.dispatch('changeGleam', true);
    },
    isSafari() {
      return navigator.vendor
        && navigator.vendor.indexOf('Apple') > -1
        && navigator.userAgent
        && navigator.userAgent.indexOf('CriOS') === -1
        && navigator.userAgent.indexOf('FxiOS') === -1;
    },
  },
};
</script>

<style lang="scss">
  .header {
    h1 {
    font-family: 'broken_15regular', Arial, sans-serif;
    font-size: 65px;
    margin-top: 15px;

    @include below(1441px) {
      font-size: 40px;
    }

    @media screen and (max-height: 960px) {
        font-size: 40px;
    }
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      display: inline-block;
      margin: 0 10px;
    }

    a {
      color: #fff;
    }

    .hidden {
      visibility: hidden;
    }

    @include below(769px) {
      .rebel-figure {
        position: absolute;
        bottom: 0;
        right: 0;
        width: auto;
        height: 100%;
      }
    }

    .wrapper-rebel-figure {
      overflow: hidden;
      width: 61vh;
      height: 96vh;
      position: absolute;
      right: 6vh;
      bottom: 0vh;
      z-index: 13;
      transform: translateZ(13px);

      // overflow: hidden;
      // width: 61vh;
      // position: absolute;
      // right: 2vh;
      // bottom: -18vh;
      // z-index: 13;
      // transform: translateZ(13px);

      video {
        width: 100%;
        height: 100%;
      }
      .rebel-figure {
        width: auto;
        height: 112vh;
        position: relative;

        &.light-1 {
          left: -58vh;
        }

        &.light-2 {
          left: 2vh;
        }

        &.light-3 {
          left: -120vh;
        }

        &.light-4 {
          left: -182vh;
        }

        @include below(769px) {
          position: absolute;
          bottom: 0;
          right: 0;
          width: auto;
          height: 40%;
        }
      }
    }

    .text-content {
      position: absolute;
      top: 52%;
      right: 56%;
      width: 100%;
      transform: translateZ(12px) translateY(-50%);
      z-index: 14;
      color: #fff;
      max-width: 800px;

      @include below(1921px) {
        max-width: 720px;
        right: 52%;
      }

      @include below(1350px) {
        top: 50%;
        left: 50%;
        right: auto;
        width: 90vw;
        transform: translateZ(12px) translate(-50%, -50%);
      }

      @include below(1600px) {
        right: 37%;
      }

      @include below(769px) {
        top: 49% !important;
        transform: translateZ(12px) translate(-50%, -50%) !important;
      }

      p {
        margin-top: 10px;
        font-weight: bold;
        font-family: 'digitalstripregular';
        text-align: justify;
        font-size: 14px;
        line-height: 26px;
        color: #fec246;
        filter: drop-shadow(2px 4px 6px black);
        text-transform: uppercase;

        @include below(1023px) {
          font-size: 12px;
          line-height: 20px;
          background: rgba(0,0,0,0.5);
          padding: 10px;
          border-radius: 10px;

          display: none;
        }

        @media screen and (max-height: 850px) {
          display: none;
        }

        @include below(1441px) {
          font-size: 12px !important;
        }

        @media screen and (max-height: 960px) {
          font-size: 12px !important;
        }

        @include mobile() {
          font-size: 10px !important;
        }
      }
    }

    .lst-icons {
      margin-top: 50px;

      @include below(768px) {
        display: none;
      }

      @include mobile {
        margin-top: 10px;
      }

      img {
        margin-top: 20px;
        max-width: 100%;
        width: 140px;
        height: auto;
        position: static;
        margin: 0 auto 10px;
        border: 4px solid #fff;
        border-radius: 50%;
      }
    }

    .main-icon {
      display: inline-block;
      margin-top: 15px;
      font-family: 'century-gothic-bold-italic';
      font-size: 12px;
      cursor: default;
      text-decoration: none;
      text-transform: uppercase;
      height: 180px;

      span {
        font-size: 16px;
        margin-top: 0px;
        display: block;
        line-height: 23px;
      }

      img {
        position: relative;
        width: auto;
        height: auto;
        top: 0;
        left: 50%;
        bottom: auto;
        transform: translateX(-50%);
        width: 135px;
        border-radius: 50%;
        margin: 10px 0;
      }
    }
  }

  @media screen and (max-height: 1038px) {
    .header .lst-icons {
      @include mobile() {
        display: none !important;
      }
    }
  }

  .button-1.btn-community {
    display: block;
    width: 100%;
    max-width: 333px;
    margin: 50px auto 3px;
    font-size: 14px;
    &.safari {
      font-size: 14px;
    }

    img {
      width: 36px;
      float: left;
      filter: invert(1);
      position: relative;
      top: -8px;
      left: 3px;
    }

    &:hover {
      img {
        filter: invert(0);
      }
    }

    @include below(769px) {
      font-size: 12px;
      margin: 20px auto 3px;
    }
  }

  .button-2.btn-read-the-comic {
    background: #fec246;
    width: 100%;
    max-width: 162px;
    color: #000;
    margin: 12px 5px 0 0;
    min-width: 0;
    font-size: 11px;

    &.btn-teaser {
      margin: 12px 0 0 5px;
    }

    &.safari {
      font-size: 10px;
    }

    @include below(769px) {
      max-width: 155px;
    }

    @include below(361px) {
      font-size: 11px;
      max-width: 333px;
      margin: 4px 0;
    }
  }

  @media screen and (max-height: 950px) and (min-width: 768px) {
    .wrapper-buttons {
      max-width: 685px;
      margin: 30px auto 0;
      .button-1.btn-community {
        margin: 0 auto 3px;
        font-size: 13px;
        float: left;
        height: 49px;
      }
      .button-2.btn-read-the-comic {
        margin: 0 5px 0;
        float: right;
        font-size: 11px;

        &.safari {
          font-size: 10px;
        }
      }
      .wrapper-sound {
        clear: both;
      }
    }
  }

  @media screen and (max-height: 1080px) {
    .header {
      img {
        &.rebel-main-logo {
          max-width: 450px;
          margin-top: -70px;

          @include below(769px) {
            margin-top: 0px;
          }
        }
      }
      .text-content {
        top: 54%;
        p {
          font-size: 12px;
          text-transform: uppercase;
        }
      }
      .lst-social-media {
        li {
          width: 50px;
        }
      }
      h1 {
        font-size: 45px;
      }
    }
    .home {
      .header {
        img {
          &.rebel-main-logo {
            max-width: 620px;
            margin: 60px auto 0px;
          }
        }
      }
    }
  }

  .icon-coin {
    width: auto;
    height: 100%;
  }

  img {
    &.rebel {
      &-main-logo {
        position: relative;
        width: 100%;
        height: auto;
        max-width: 680px;
        margin: -90px auto 0px;
        -webkit-transform:translateZ(5px);
        -moz-transform:translateZ(5px);
        -o-transform:translateZ(5px);
        transform:translateZ(5px);
        z-index: 5;
        filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.5));

        @include below(1023px) {
          width: 85vw;
          left: 50%;
          margin: 0 0 20px;
          transform: translateX(-50%);
        }
      }
    }
  }
</style>
