<template>
  <div class="modal" ref="modal"
    @click="$store.dispatch(modalElm, false)"
    @keyup.esc="$store.dispatch(modalElm, false)">
    <div class="wrapper-modal">
      <slot></slot>
      <button title="Close (Esc)" type="button"
        class="modal-close-button" @click="$store.dispatch(modalElm, false)">×</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComp',
  components: {
  },
  props: {
    modalElm: String,
  },
  mounted() {
    this.$refs.modal.focus();
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
  .modal {
    background: rgba(0, 0, 0, 0.9);
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999999;

    .modal-close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #000;
      opacity: 1;
      font-weight: 400;
      font-family: Montserrat;
      width: 32px;
      height: 32px;
      line-height: 32px;
      background: #fff;
      border-radius: 50%;
      border: 0;
      font-size: 32px;
      cursor: pointer;
    }

    &.modal-book {
      .wrapper-modal {
        overflow: unset;
      }
    }

     &.modal-wiki {
      .wrapper-modal {
        overflow: unset;
        max-width: 1284px;

        .iframe-modal {
          width: 100%;
        }
      }

      .modal-close-button {
        border: 1px solid #000;
        top: -15px;
        right: -15px;
        @include below(768px) {
          top: -45px;
          right: 10px;
        }
      }
     }

     &.modal-gleam {
       .wrapper-modal {
         max-width: 640px;
         overflow: unset;
       }
       .modal-close-button {
         top: -17px;
       }
     }

    .video-container-modal {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }

    .wrapper-modal {
      border-radius: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 75vw;
      max-height: 97vh;
      overflow: hidden;
      box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);

      @include below(768px) {
         width: 100vw
      }
    }
  }
</style>
