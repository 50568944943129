<template>
  <MainMenu />
  <section class="section-about">
    <HeaderCommingSoon />
    <video class="bg-video" ref="bgcity"  autoplay muted loop v-if="!$store.state.mobile"
      @playing="backgroundPlaying($event)">
      <source :src="require(`@/assets/videos/rebel-city-lightning-reflections.mp4`)"
        type="video/mp4">
    </video>
  </section>
  <Modal v-if="$store.state.wiki" :modalElm="'changeWiki'" class="modal-wiki"
    @keyup.esc="$store.dispatch('changeWiki', false)" tabindex="0">
    <iframe src="https://wiki.cryptorebel.app/" class="iframe-modal"
      title="Whitepaper" frameborder="0" allowtransparency="true"></iframe>
  </Modal>
  <Modal v-if="$store.state.comicAbout" :modalElm="'changeComicAbout'">
    <a href="javascript:;" class="button button-1"
      @click="$store.dispatch('changeComic', true);$store.dispatch('changeComicAbout', false)">
        Comic
    </a>
    <a href="javascript:;" class="button button-1"
      @click="$store.dispatch('changeComic', false);$store.dispatch('changeComicAbout', true)">
        About
    </a>
    <iframe v-if="!$store.state.mobile" src="https://cryptorebel.app/comic-book-v2-about/?v=3" class="iframe-modal" title="Comic"
      frameborder="0" allowtransparency="true"></iframe>
    <iframe v-if="$store.state.mobile" src="https://cryptorebel.app/comic-book-about/?v=3" class="iframe-modal" title="Comic"
      frameborder="0" allowtransparency="true"></iframe>
  </Modal>
  <Modal v-if="$store.state.comic" :modalElm="'changeComic'" class="modal-book">
    <a href="javascript:;" class="button button-1"
      @click="$store.dispatch('changeComic', true);$store.dispatch('changeComicAbout', false)">
        Comic
    </a>
    <a href="javascript:;" class="button button-1"
      @click="$store.dispatch('changeComic', false);$store.dispatch('changeComicAbout', true)">
        About
    </a>
    <iframe v-if="!$store.state.mobile" src="https://cryptorebel.app/comic-book-v2/?v=8" class="iframe-modal"
      title="Comic" frameborder="0" allowtransparency="true"></iframe>
    <iframe v-if="$store.state.mobile" src="https://cryptorebel.app/comic-book/?v=8" class="iframe-modal"
      title="Comic" frameborder="0" allowtransparency="true"></iframe>
  </Modal>
  <Modal v-if="$store.state.trailer" :modalElm="'changeTrailer'">
    <Trailer :modalElm="changeTrailer" />
  </Modal>
  <Modal v-if="$store.state.share" :modalElm="'changeShare'" class="modal-share"
    @keyup.esc="$store.dispatch('changeWiki', false)" tabindex="0">
    <img class="img-share" :src="`https://marketing-system-cryptorebel.s3.sa-east-1.amazonaws.com/${imgShare}`" alt="Img Facebook" />
  </Modal>
</template>

<script>
import MainMenu from '@/components/MainMenu_v2.vue';
import HeaderCommingSoon from '@/components/Header_v2.vue';
import Modal from '@/components/Modal.vue';
import Trailer from '@/components/Trailer.vue';

export default {
  name: 'CoomingSoon',
  components: {
    MainMenu,
    Modal,
    HeaderCommingSoon,
    Trailer,
  },
  data() {
    return {
      bw: null,
      bh: null,
      w: null,
      h: null,
      mw: null,
      mh: null,
      videoPlayed: [],
      firstLoad: false,
      unlock: true,
      imgShare: '',
    };
  },
  computed: {
    playerBG() {
      return this.$refs.bgcity;
    },
    audio() {
      return this.$store.state.audio;
    },
  },
  watch: {
    audio(newVal) {
      if (newVal) {
        this.playerBG.muted = false;
      } else {
        this.playerBG.muted = true;
      }
    },
  },
  mounted() {
    this.resizeMovie();
    window.addEventListener('resize', this.resizeMovie);
    if (this.$store.state.mobile) {
      this.$store.dispatch('changeLoader', false);
    }

    if (window.location.search.indexOf('share') !== -1) {
      this.$store.dispatch('changeShare', true);
      this.imgShare = `${window.location.search.split('share=')[1]}.webp`;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeMovie);
  },
  methods: {
    scrollTo(e) {
      e.preventDefault();
      const header = document.querySelector('.section-about');
      window.scroll({
        top: header.offsetTop,
        behavior: 'smooth',
      });
    },
    backgroundPlaying() {
      const self = this;
      self.firstLoad = true;
      this.$store.dispatch('changeLoader', false);
      if (window.location.href.indexOf('/wiki') !== -1) {
        this.$store.dispatch('changeWiki', true);
      }
    },
    gcd(a, b) {
      return (b === 0) ? a : this.gcd(b, a % b);
    },
    resizeMovie() {
      const $w = window;
      this.bw = 1200;
      this.w = $w.innerWidth;
      this.h = $w.innerHeight;
      const ws = $w.screen.width;
      const hs = $w.screen.height;
      const r = this.gcd(ws, hs);
      const aspectRatio = ws / r > 16 ? 16 : 16;

      this.bh = (this.bw / aspectRatio) * 9;
      this.mw = this.w;
      this.mh = Math.round(this.bh * (this.mw / this.bw));

      if (this.mh < this.h) {
        this.mh = this.h;
        this.mw = Math.round(this.bw * (this.mh / this.bh));
      }
    },
  },
};
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    border: 0;
  }

  html, body {
    background: #000;
    overflow-x: hidden;
  }

  section {
    height: 100vh;
    overflow: hidden;
    position: relative;

    &:before,
    &:after {
      content: " ";
      width: 100vw;
      box-shadow: inset 0 0 35px 10px #000;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &:after {
      box-shadow: inset 0 0 2000px 20px #000;
    }
  }

  .bg-video {
    position: relative;
    z-index: 0;
    height: v-bind('`${mh}px`') !important;
    width: v-bind('`${mw}px`') !important;
    margin-top: v-bind('`${(h - mh) / 2}px`');
    margin-left: v-bind('`${(w - mw) / 2}px`');
  }

  .scroll-downs {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 5%;
    left: 0;
    margin: auto;
    width: 100px;
    height: 100px;
    z-index: 10;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    @include below(769px) {
      bottom: 10%;
    }

    .arrow {
      position: absolute;
      bottom: -20px;
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      border-right: 0.1em solid #fff;
      border-top: 0.1em solid #fff;
      transform: rotate(135deg);
      left: 43%;
    }

    .mousey {
      width: 3px;
      padding: 10px 15px;
      height: 35px;
      border: 2px solid #fff;
      border-radius: 25px;
      opacity: 0.75;
      box-sizing: content-box;
    }
    .scroller {
      width: 3px;
      height: 10px;
      border-radius: 25%;
      background-color: #fff;
      animation-name: scroll;
      animation-duration: 2.2s;
      animation-timing-function: cubic-bezier(.15,.41,.69,.94);
      animation-iteration-count: infinite;
    }
  }

  @keyframes scroll {
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px); opacity: 0;}
  }

.button {
  text-align: center;
  text-decoration: none;
  font-family: digitalstripregular;
  font-style: normal;
  font-weight: 700;
  letter-spacing: .5px;
  color: #fff;
  align-items: center;
  font-size: 15px;
  line-height: 21px;
  padding: 15px 20px 12px;
  display: inline-block;
  min-width: 16em;
  margin: 20px 20px;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);

  @include below(769px) {
    min-width: 0;
  }
}

.button-1 {
  background-color: #e34949;
  &:hover {
    color: #e34949;
    background: #fff;
  }
}

.button-2 {
  background-color: #000;
  &:hover {
    color: #000;
    background: #fff;
  }
}

.iframe-modal {
  width: 74vw;
  height: 88vh;

  @include below(769px) {
    width: 98vw;
    height: 85vh;
  }
}

.section-about {
  @include below(769px) {
    background: url('~@/assets/images/background-mobile.jpg') 0 0 no-repeat;
    background-size: 100%;
  }
}

.modal-share {
  .wrapper-modal {
    width: auto;
  }

  img {
    width: auto;
    height: 98vh;
    margin: 0 auto;
  }
}
</style>
