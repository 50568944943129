<template>
  <div class="loader" v-if="$store.state.loader">
    <Loader />
  </div>
  <router-view />
</template>

<script>
import Loader from '@/components/Loader.vue';

export default {
  name: 'app',
  components: {
    Loader,
  },
  computed: {
    gleamStatus() {
      return this.$store.state.gleam;
    },
  },
  watch: {
    gleamStatus() {
      this.activeGiveAway();
    },
  },
  created() {
    this.isMobile();
  },
  methods: {
    activeGiveAway() {
      if (!document.querySelectorAll('#gleam-script').length) {
        const gleamjs = document.createElement('script');
        gleamjs.setAttribute('src', 'https://widget.gleamjs.io/e.js');
        gleamjs.setAttribute('id', 'gleam-script');
        document.head.appendChild(gleamjs);
      }
    },
    isMobile() {
      if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
        && 'ontouchstart' in document.documentElement)) {
        this.$store.dispatch('isMobile', true);
      } else {
        this.$store.dispatch('isMobile', false);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
  }
}

.loader {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999999999;

  .sound-icon {
    width: 35px;
    height: 35px;
    display: block;
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translateX(-50%);

    path {
      fill: #fff;
    }
  }
}
</style>
