<template>
  <div>
    <svg id="projectionScreen" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200">
      <defs>
        <mask id="textMask">
          <rect x="213" y="585" width="774" height="130" fill="#fff"/>
        </mask>
      </defs>
      <g id="film" opacity="0">
        <g stroke-width="2">
          <circle cx="600" cy="450" r="175" stroke="#111"  fill="none" />
          <circle cx="600" cy="450" r="150" stroke="#fff" fill="none" />
          <circle cx="600" cy="450" r="110" stroke="#fff"  fill="none" />
          <image :href="require(`@/assets/images/circle-loader.webp`)" x="450" y="300"
            width="300" height="300"></image>
        </g>

        <line id="rotator" x1="600" y1="275" x2="600" y2="450" stroke="#333" stroke-width="3" />
        <text id="counter" text-anchor="middle"
          x="595" y="510" font-size="200"
          style="opacity: 0.6;font-family: Teko;
          text-transform: uppercase;transform: translateY(2px);top: 0;">1</text>

        <g style="transform: translate(0%, -12.35%) scale(3)">
          <path id="myTextPath" d="M 64,0 A 64,64 0 0 1 0,64 A 64,64 0 0 1 -64,0
            A 64,64 0 0 1 0,-64 A 64,64 0 0 1 0,64"
            transform="translate(200,200)" fill="none" stroke="#d72b27"
            stroke-width="15">
          </path>
          <text fill="#ffc246" x="279" y="0" stroke-width="0" style="
            font-family: Teko;
            text-transform: uppercase;
            transform: translateY(2px);
            top: 10px;
          "><textPath xlink:href="#myTextPath"><tspan dy="3">Loading</tspan></textPath></text>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import {
  Linear, gsap,
} from 'gsap';

export default {
  name: 'MenuIcons',
  data() {
    return {
      isActive: false,
      sound: false,
    };
  },
  mounted() {
    this.countDown();
  },
  methods: {
    countDown() {
      const demo = document.getElementById('projectionScreen');
      const countDown = document.getElementById('counter');
      const masterTimeline = gsap.timeline({ delay: 0.1 });
      let count = 10;

      gsap.set(demo, {
        transformOrigin: 'center', autoAlpha: 1, xPercent: -50, yPercent: -50,
      });
      gsap.set('#rotator, #supports', { transformOrigin: '50% 100%' });
      countDown.textContent = count;
      function projector() {
        const tl = gsap.timeline();
        tl.to('#film', { duration: 1.5, autoAlpha: 1 });
        return tl;
      }
      const self = this;
      function theCount() {
        const tl = gsap.timeline({
          repeat: count - 1,
          onRepeat() {
            // eslint-disable-next-line no-plusplus
            count--;
            countDown.textContent = count;
            if (count === 1) {
              setTimeout(() => {
                self.$emit('loaded', true);
                self.$store.dispatch('changeLoader');
              }, 1000);
            }
          },
        });
        tl.to('#rotator', { duration: 1, rotation: 360, ease: Linear.easeNone });
        return tl;
      }
      masterTimeline
        .add(projector())
        .add(theCount(), '-=1.5');
      this.sound = !this.sound;
    },
  },
};
</script>
<style lang="scss">
  #projectionScreen {
    overflow:hidden;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    visibility:hidden;
    opacity:0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(0.6);
  }
</style>
